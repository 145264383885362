import React, { useRef } from "react";
import Slider from "react-slick";
import { Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import imgN1 from "../../assets/image/customers/daya_nail_story.png";
import imgN2 from "../../assets/image/customers/jd_cafe_story.png";
import imgN3 from "../../assets/image/customers/feazy_net_story.png";
import imgN4 from "../../assets/image/customers/ballarat_care_story.png";
import imgN5 from "../../assets/image/customers/whittlesey_church_story.png";
import imgN6 from "../../assets/image/customers/intercon_management_consulting_story.png";
import imgN7 from "../../assets/image/customers/kohvieri_story_featured_image.png";
import imgN8 from "../../assets/image/customers/ups_store_creekside.png";
import imgN9 from "../../assets/image/customers/lamex_restaurant_story_thumbnail.png";
import imgN10 from "../../assets/image/customers/brick_finance_customer_story_thumbnail.png";
import imgN11 from "../../assets/image/customers/next_wave_neurodiagnostics_customer_story_thumbnail.png";
import imgN12 from "../../assets/image/customers/the_big_event_photography_customer_story_thumbnail.png";

const stories = [
  {
    href: "/customers/the-big-event-photography-customer-story",
    alt: "How The Big Event transformed their employee management with Camelo",
    imgSrc: imgN12,
    title:
      "How The Big Event transformed their employee management with Camelo",
  },
  {
    href: "/customers/next-wave-neurodiagnostics-customer-story",
    alt:
      "How Next Wave Neurodiagnostics overcomes scheduling challenges with Camelo",
    imgSrc: imgN11,
    title:
      "How Next Wave Neurodiagnostics overcomes scheduling challenges with Camelo",
  },
  {
    href: "/customers/brick-finance-customer-story",
    alt:
      "How Brick uses Camelo to ensure 24/7 coverage while maintaining compliance & team well-being",
    imgSrc: imgN10,
    title:
      "How Brick uses Camelo to ensure 24/7 coverage while maintaining compliance & team well-being",
  },
  {
    href: "/customers/la-mex-restaurant-story",
    alt:
      "How La Mex Restaurant stays organized & keeps their crew updated using Camelo",
    imgSrc: imgN9,
    title:
      "How La Mex Restaurant stays organized & keeps their crew updated using Camelo",
  },
  {
    href: "/customers/ups-store-creekside",
    alt:
      "How Camelo helps The UPS Store Creekside manage schedules, messages, and tasks better",
    imgSrc: imgN8,
    title:
      "How Camelo helps The UPS Store Creekside manage schedules, messages, and tasks better",
  },
  {
    href: "/customers/kohvieri_coffee_shop_story",
    alt: "How Kohvieri Coffee Shop Manage Young Workers Better with Camelo",
    imgSrc: imgN7,
    title: "How Kohvieri Coffee Shop manage young workers better with Camelo",
  },
  {
    href: "/customers/ballarat_care_story",
    alt: "How Camelo helps Ballarat Care manage support workers",
    imgSrc: imgN4,
    title: "How Camelo helps Ballarat Care manage support workers",
  },
  {
    href: "/customers/intercon_management_consulting_story",
    alt: "How Camelo improves the scheduling process in IMC",
    imgSrc: imgN6,
    title: "How Camelo improves the scheduling process in IMC",
  },
  {
    href: "/customers/whittlesey_church_story",
    alt:
      "How Camelo helps Whittlesey Christian Church manage an enthusiastic team",
    imgSrc: imgN5,
    title:
      "How Camelo helps Whittlesey Christian Church manage an enthusiastic team",
  },
  {
    href:
      "/customers/how-camelo-helps-this-nail-salon-owner-manage-her-business-and-time",
    alt: "How Camelo Helps this Nail Salon owner manage her business and time",
    imgSrc: imgN1,
    title:
      "How Camelo Helps This Nail Salon Owner Manage Her Business and Time",
  },
  {
    href:
      "/customers/from-complex-spreadsheets-to-one-simple-time-crew-tracking-app",
    alt:
      "How This Coffee Shop Owner Turned From Complex Spreadsheets to One Simple App",
    imgSrc: imgN2,
    title:
      "How This Coffee Shop Owner Turned From Complex Spreadsheets to One Simple App",
  },
  {
    href: "/customers/shifts-planning-time-clocks-for-call-center",
    alt:
      "How This Call Center Manager No Longer Spreaded Work Across Different Apps",
    imgSrc: imgN3,
    title:
      "How This Call Center Manager No Longer Spreaded Work Across Different Apps",
  },
];

const News = () => {
  const elSlider = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    arrows: false,
    className: "single-slide",

    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* <!-- News section --> */}
      <div className="news-section pt-12 pb-8 py-lg-23 bg-default-9 position-relative paperwapper">
        <Container>
          <Row className="justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9">
              <div className="section-title text-center mb-11 mb-lg-18">
                <h2 className="title gr-text-4 mb-6">Success stories</h2>
                <p className="gr-text-8 px-lg-8 mb-0">
                  Businesses of every size, shape and kind have already made
                  Camelo the place where their work happens
                </p>
              </div>
            </div>
          </Row>
          <div>
            <div className="job-feature-slider-arrows ml-auto mr-6">
              <button
                className="slick-prev slick-arrow"
                aria-label="Previous"
                type="button"
                onClick={() => {
                  elSlider.current.slickPrev();
                }}
              >
                Previous
              </button>
              <button
                className="slick-next slick-arrow"
                aria-label="Next"
                type="button"
                onClick={() => {
                  elSlider.current.slickNext();
                }}
              >
                Next
              </button>
            </div>
            <div
              className="justify-content-center mt-5"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              <Slider {...settings} ref={elSlider}>
                {stories.map(({ href, alt, imgSrc, title }, index) => (
                  <div key={index}>
                    <div className="customer-story-card mb-9 gr-hover-shadow-1 h-lg-100 rounded-10 bg-white mx-6">
                      <a className="card-img" href={href} target="_blank">
                        <LazyLoadImage
                          alt={alt}
                          src={imgSrc}
                          className="w-100 rounded-top-10"
                        />
                      </a>
                      <div
                        className="card-content px-9 py-8 bg-white rounded-bottom-10"
                        style={{ minHeight: "200px" }}
                      >
                        <a href={href} target="_blank">
                          <h3 className="title text-blackish-blue gr-text-7 mb-0">
                            {title}
                          </h3>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default News;
